import request from '@/services/request'

export function getOrderList(data) {
  return request({
    url: '/3tOrder/getOrderList',
    method: 'post',
    data: data
  })
}
export function getOrder(order_num) {
  return request({
    url: '/3tOrder/getOrder/' + order_num,
    method: 'get'
  })
}
