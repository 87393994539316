<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <!-- 填寫資料 -->
    <div v-if="detail" class="inter-content container-fluid">
      <vsa-list :init-active="true" :auto-collapse="false">
        <vsa-item>
          <vsa-heading> 訂單資訊 </vsa-heading>
          <vsa-content>
            <div class="content-wrap">
              <div class="order-area">
                <div class="box-group">
                  <div class="box-items">
                    <div class="box-th">
                      訂單編號
                    </div>
                    <div class="box-td">
                      {{ detail.order_num }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      訂購日期
                    </div>
                    <div class="box-td">
                      {{ detail.create_at.substring(0, 10) }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      支付方式
                    </div>
                    <div class="box-td">
                      {{ paymentNameLabel }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      發票種類
                    </div>
                    <div class="box-td">
                      {{ invoiceTypeName }}
                    </div>
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      訂單備註
                    </div>
                    <div class="box-td nl2br" v-text="detail.remark" />
                  </div>
                  <div class="box-items">
                    <div class="box-th">
                      訂單金額
                    </div>
                    <div class="box-td">
                      <span>{{ detail.use_point }}</span>點 ＋ NT$<span>{{ detail.fee_total | priceFormat }}</span>
                    </div>
                  </div>
                </div>
              </div>
  
              <div
                v-for="(item, index) in detail.voucher"
                :key="index"
                class="order-area-deatil"
              >
                <div class="sn">
                  {{ index + 1 }}.
                </div>
                <div class="name">
                  {{ item.item_name }}
                </div>
                <div class="iteme">
                  {{ item.plan_name }}{{ detail.items[0].price_name | pricename }}
                  <span
                    v-if="
                      detail.items[0].refund_type &&
                        detail.items[0].refund_type.toString() === '2'
                    "
                    class="refundtext"
                  >
                    不可取消
                  </span>
                </div>
                <div class="state">
                  <p :class="getStatusClass(item)">
                    {{ getStatusLabel(item) }}
                  </p>
                  <div
                    v-if="detail.is_voucher && detail.is_cancel !== 't'"
                    class="out-line"
                  >
                    <a @click="toVoucherDetail(item)">查看票券</a>
                  </div>
                </div>
              </div>
            </div>
          </vsa-content>
        </vsa-item>
      </vsa-list>

      <vsa-list>
        <vsa-item>
          <vsa-heading> 聯絡人資訊 </vsa-heading>
          <vsa-content>
            <div class="content-wrap">
              <div class="contact-info">
                <ul>
                  <li>姓名：{{ detail.bill_name }}</li>
                  <li>電話：{{ detail.bill_phone }}</li>
                  <li>信箱：{{ detail.bill_email }}</li>
                </ul>
              </div>
            </div>
          </vsa-content>
        </vsa-item>
      </vsa-list>
      <vsa-list v-if="$store.getters.shopInfo">
        <vsa-item>
          <vsa-heading>
            {{ $store.getters.shopInfo.cancel_name || '取消政策' }}
          </vsa-heading>
          <vsa-content>
            <div class="content-wrap">
              <div class="main">
                <dl>
                  <dt>{{ $store.getters.shopInfo.cancel_name || '取消政策' }}</dt>
                  <dd
                    class="nl2br"
                    v-text="$store.getters.shopInfo.cancel_policy"
                  />
                </dl>
              </div>
            </div>
          </vsa-content>
        </vsa-item>
      </vsa-list>
    </div>

    <div class="contact-fix">
      <button class="btn btn-next" type="button" @click="toContact()">
        聯絡客服
      </button>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { getOrder } from '@/api/order'

import { VsaList, VsaItem, VsaHeading, VsaContent } from 'vue-simple-accordion'

export default {
  name: 'OrderFinish',
  components: {
    HeaderInter,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent
  },
  data() {
    const contactForm = {
      from_name: null,
      from_email: null,
      content: null,
      order_id: null
    }

    return {
      detail: false,
      contactForm: contactForm,
      // READY 準備中 DONE 已取得憑証 CANCEL 作廢
      voucherStatus: {
        READY: '準備中',
        DONE: '已取得憑証',
        REDEEM: '已使用',
        CANCEL: '作廢'
      }
    }
  },
  computed: {
    paymentNameLabel: function() {
      const point = parseInt(this.detail.use_point)
      return this.detail.paytype_name_web + (point === 0 ? '' : ' + 點數')
    },
    invoiceTypeName: function() {
      // 1 電子發票二聯 2 二聯紙本 3 電子發票三聯 4 捐贈
      switch (this.detail.inv_type) {
        case '1':
          return '電子發票二聯'
        case '2':
          return '二聯紙本'
        case '3':
          return '電子發票三聯'
        case '4':
          return '捐贈'
        default:
          return '-'
      }
    }
  },
  created() {
    getOrder(this.$route.params.num, {}).then(response => {
      this.detail = response.data.data
    })
  },
  mounted() {},
  methods: {
    getStatusLabel(item) {
      return this.voucherStatus[item.status] !== undefined
        ? this.voucherStatus[item.status]
        : '-'
    },
    getStatusClass(item) {
      // READY 準備中 DONE 已取得憑証 CANCEL 作廢
      switch (item.status) {
        case 'READY':
          return 'pending'
        case 'DONE':
        case 'REDEEM':
          return 'accepted'
        case 'CANCEL':
          return 'refunded'
        default:
          return 'pending'
      }
    },
    toContact() {
      this.$router.push('/contact/ask/' + this.detail.order_num)
    },
    toVoucherDetail(item) {
      this.$router.push('/voucher/detail/' + item.voucher_num)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
.content-wrap {
  padding: 0 15px;
}
.order-area {
  // padding: 0 15px;
  margin-bottom: 10px;
  .box-group {
    .box-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.25rem;
      .box-th {
        padding: 5px 15px 5px 0px;
        white-space: nowrap;
      }
      .box-td {
        text-align: right;
        padding-left: 10px;
        span {
          color: rgba($main-color, $alpha: 1);
          font-weight: bolder;
        }
      }
    }
  }
  .box-article {
    font-size: 1rem;
    line-height: 1.85rem;
    color: rgba($font-color, $alpha: 1);
    padding-bottom: 2rem;
  }
}

.order-area-deatil {
  border-top: 1px solid rgba($font-color, $alpha: 0.35);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0;
  .sn {
    flex: 0 0 calc(5%);
  }
  .name {
    flex: 0 0 calc(45% - 15px);
  }
  .iteme {
    flex: 0 0 calc(30% - 15px);
  }
  .state {
    flex: 0 0 20%;
    p {
      padding: 0.15rem 0.35rem;
      margin: 0;
    }
    p.accepted {
      border: 1px solid rgba($notice-color, $alpha: 0.65);
      color: rgba($notice-color, $alpha: 0.65);
      text-align: center;
      border-radius: 55px;
    }
    p.pending {
      background: rgba($notice-color, $alpha: 0.65);
      color: rgba($white-color, $alpha: 1);
      text-align: center;
      border-radius: 55px;
    }
    p.shipped {
      background: rgba($notice-color, $alpha: 1);
      color: rgba($white-color, $alpha: 1);
      text-align: center;
      border-radius: 55px;
    }
    p.refunded {
      background: rgba($font-color, $alpha: 1);
      color: rgba($white-color, $alpha: 1);
      text-align: center;
      border-radius: 55px;
    }
    p.refunded-text {
      color: rgba($font-color, $alpha: 1);
    }
    .out-line {
      color: rgba($main-color, $alpha: 1);
      margin-top: 0.65rem;
      border-radius: 50px;
      text-align: center;
      a {
        color: rgba($main-color, $alpha: 1);
      }
    }
  }
}

.main {
  p {
    margin: 0 0 0.5rem;
    padding: 0;
  }
  dl {
    padding-bottom: 1rem;
  }
  dt {
    color: $main-color;
    margin-bottom: 0.35rem;
  }
  dd {
    font-size: 1rem;
    line-height: 1.85rem;
    margin: 0;
    padding: 0;
  }
  dd span {
    color: $sec-color;
  }
}
.contact-info {
  line-height: 1.85rem;
  margin: 0;
  padding: 0;
  ul {
    line-height: 1.85rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
.contact-fix {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 68px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 999;
  color: rgba($main-color, $alpha: 1);
  background: rgba($white-color, $alpha: 1);
  box-shadow: 0px -10px 20px 0px rgba($main-color, $alpha: 0.35);
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.8s linear;
  .info-box {
    display: flex;
    flex-direction: column;
    color: rgba($font-color, $alpha: 1);
    line-height: 1rem;
    em {
      font-style: normal;
      big {
        color: rgba($notice-color, $alpha: 1);
        padding: 0 3px 0 0;
        font-size: 1.15rem;
        font-weight: bolder;
      }
    }
  }
  .btn {
    flex: 0 0 100%;
    font-size: 1rem;
    border-radius: 0;
    padding: 12px;
  }
  .btn-next {
    background: rgba($main-color, $alpha: 1);
    color: $white-color;
  }
}
.form-area {
  display: flex;
  flex-direction: column;
  .btn {
    flex: 0 0 100%;
    border-radius: 0;
    padding: 10px;
  }
  .btn-next {
    background: rgba($main-color, $alpha: 1);
    color: $white-color;
  }
}

.isscroll {
  .contact-fix {
    opacity: 0;
  }
}

.refundtext {
  display: inline;
  line-height: 1;
  background-color: $price-color;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 8px;
  white-space: nowrap;
}

@media screen and (min-width: 640px) {
  .contact-fix {
    max-width: 640px;
  }
}
@media screen and (min-width: 768px) {
  .contact-fix {
    max-width: 768px;
  }
}
</style>
